.logo-container {
  //    z-index: -1;
  //    width: 400px;
  //    height: 609px;
  //    position: absolute;
  //    top: 50%;
  //    right: 15%;
  //    bottom: 0;
  //    left: auto;
  //    margin: auto;
  //    border: 2px solid red;
  
      svg {
        width: 100%;
        height: auto;
        bottom: 0;
        transform: rotateZ(30deg) !important;
      }
  
      .solid-logo {
        position: absolute;
        opacity: 0;
        top: 5%;
        right: 45%;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 40%;
        z-index: 1;
        border-radius:50px; /* regular */
  
      @media (max-width: 480px) { // For mobile screen
        position: absolute;
        opacity: 1;
        top: 12%;
        right: -15%;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 80%;
        z-index: 1;
        border-radius:50px; /* regular */
  
       }
  
      }
  
    }
  
    .svg-container {
      stroke: #ffd700;
      stroke-width: 10;
    }  